<template>
  <header>
    <v-app-bar app flat>
      <v-toolbar-title><router-link class="toolbar-title" to="/">{{ config.siteName }}</router-link></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/login"><v-icon>mdi-login</v-icon>ログイン</v-btn>
    </v-app-bar>
  </header>
</template>

<script>
export default {
  name: "FrontHeader"
}
</script>

<style scoped>
.toolbar-title {
  color: inherit;
  text-decoration: none;
}

</style>