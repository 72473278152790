export default {
  "menuItems": [
    {
      "name": "ダッシュボード",
      "url": "/dashboard",
      "icon": "mdi-desktop-mac-dashboard",
    },
    {
      "name": "出社予定日",
      "url": "/date-of-arrival",
      "icon": "mdi-home-city-outline",
    },
    {
      "name": "全体表示",
      "url": "/all-list",
      "icon": "mdi-format-list-text",
    },
    // {
    //   "name": "グループ",
    //   "url": "/group",
    //   "icon": "mdi-account-multiple",
    //   "admin": true,
    // },
    // {
    //   "name": "メンバー",
    //   "url": "/member",
    //   "icon": "mdi-account-details",
    //   "admin": true,
    // },
    // {
    //   "name": "ステータス",
    //   "url": "/status",
    //   "icon": "mdi-list-status",
    //   "admin": true,
    // },
    // {
    //   "name": "登録情報",
    //   "url": "/registration-information",
    //   "icon": "mdi-information",
    //   "admin": true,
    // }
  ]
}