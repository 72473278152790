<template>
  <v-app>
    <component v-bind:is="layout()"></component>
  </v-app>
</template>

<script>

import Default from "./components/layout/Default";
import Simple from "./components/layout/Simple";

export default {
  name: 'App',
  components: {
    Default,
    Simple,
  },
  methods: {
    layout() {
      return (this.$route.meta.layout) ? this.$route.meta.layout : 'default';
    },
    createMetaTitle(to) {
      if (to.meta.title) {
        document.title = this.config.siteName + to.meta.title;
      } else {
        document.title = this.config.siteName
      }
      console.log(document.title);
    },
    createMataDescription(to) {
      if (to.meta.description) {
        document.querySelector("meta[name='description']").setAttribute('content', to.meta.description)
      } else {
        // document.querySelector("meta[name='description']").setAttribute('content', '')
      }
    },
  },
  data: () => ({
    //
  }),
  watch: {
    '$route' (to) {
      this.createMetaTitle(to);
      this.createMataDescription(to);
    }
  }
};
</script>
