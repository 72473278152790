import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    status: false,
    userInfo: {},
  },
  getters: {
    users: state => state.userInfo,
  },
  mutations: {
    onAuthStateChanged(state, user) {
      state.user = user; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.status = status; //ログインしてるかどうか true or false
    },
    onUserInfoChanged(state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
  },
  modules: {
    user(state) {
      return state.user;
    },
    isSignedIn(state) {
      return state.status;
    },
    userInfo(state) {
      return state.userInfo;
    }
  }
})
