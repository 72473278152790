import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics"
import "firebase/compat/firestore"
import "firebase/compat/database"
import store from "../store"
import router from "../router";

const firebaseConfig = {
    apiKey: "AIzaSyAYqRX-23zbyx0P9uCsxjxxvK56BJ7qMJE",
    authDomain: "dice-presence-project.firebaseapp.com",
    databaseURL: "https://dice-presence-project.firebaseio.com",
    projectId: "dice-presence-project",
    storageBucket: "dice-presence-project.appspot.com",
    messagingSenderId: "515138057352",
    appId: "1:515138057352:web:e1f30d17bf852ffc4bdb3d",
    measurementId: "G-CBWB4Z37PP"
};

firebase.initializeApp(firebaseConfig);
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsinscribe = firebase.auth().onAuthStateChanged(user => {
            unsinscribe()
            resolve(user)
        }, reject);
    });
};

firebase.login = (email, password) => {
    firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
            console.log('login')
            router.push('/dashboard')
        }).catch(error => {
        console.log(error)
        alert('メールアドレスまたはパスワードが間違っています。');
    });
}

firebase.logout = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            console.log('logout')
            router.push('/');
        });
}

firebase.onAuth = () => {
    firebase.auth().onAuthStateChanged(user => {
        user = user ? user : {};
        store.commit('onAuthStateChanged', user);
        store.commit('onUserStatusChanged', !!user.uid);
        console.log(user.uid)

        if (!user.uid) {
            return;
        }

        let collection =  firebase_db.collection('users').where('uid', '==', user.uid);
        let currentUser = [];
        collection.onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              currentUser = doc.data();
              currentUser['docId'] = doc.id;
            })
            currentUser = currentUser ? currentUser : {};
            store.commit('onUserInfoChanged', currentUser)
        });
    });
}

export default firebase;
export const firebase_db = firebase.firestore();
export const firebase_auth = firebase.auth();
export const firebase_realtime = firebase.database();
