import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from '../plugins/firebase'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '',
            layout: 'simple',
            requiresAuth: false,
        },
        component: () => import('../components/pages/front/Home')
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: '[About]',
            layout: 'simple',
            requiresAuth: false,
        },
        component: () => import('../components/pages/front/About')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '[Login]',
            layout: 'simple',
            requiresAuth: false,
        },
        component: () => import('../components/pages/front/Login')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            title: '[ダッシュボード]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/Dashboard')
    },
    {
        path: '/date-of-arrival',
        name: 'DateOfArrival',
        meta: {
            title: '[出社予定日]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/DateOfArrival')
    },
    {
        path: '/all-list',
        name: 'AllList',
        meta: {
            title: '[全体リスト]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/AllList')
    },
    {
        path: '/member',
        name: 'Member',
        meta: {
            title: '[メンバー]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/Member')
    },
    {
        path: '/group',
        name: 'Group',
        meta: {
            title: '[グループ]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/Group')
    },
    {
        path: '/registration-information',
        name: 'RegistrationInformation',
        meta: {
            title: '[登録情報]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/RegistrationInformation')
    },
    {
        path: '/status',
        name: 'Status',
        meta: {
            title: '[ステータス]',
            requiresAuth: true,
        },
        component: () => import('../components/pages/default/Status')
    },
    {
        path: '/empList',
        name: 'EmployeeAll',
        meta: {
            title: '[全体表示]',
            noLogin: true,
        },
        component: () => import('../components/pages/default/EmployeeAll')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(recode => recode.meta.requiresAuth);
    if (requiresAuth && !(await firebase.getCurrentUser())) {
        next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
        if (to.path === "/login" && (await firebase.getCurrentUser())) {
            console.log(to.path);
            next({ path: "/dashboard", query: { redirect: to.fullPath } });
        }
        next();
    }
})

export default router