<template>
  <v-footer padless color="blue-grey darken-4" app>
    <v-col
        class="text-center white--text"
        cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>{{ config.siteName }}</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>