<template>
  <div>
    <FrontHeader></FrontHeader>
    <v-main>
      <router-view></router-view>
    </v-main>
    <FrontFooter></FrontFooter>
  </div>
</template>

<script>

import FrontFooter from "../organisms/FrontFooter";
import FrontHeader from "../organisms/FrontHeader";

export default {
  name: "Simple",
  components: {
    FrontHeader,
    FrontFooter,
  }
}
</script>

<style scoped>

</style>