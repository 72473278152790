<template>
  <header>
    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="drawer=!drawer" v-if="this.$route.meta.noLogin !== true"></v-app-bar-nav-icon>
      <v-toolbar-title>フォークオリア</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="userSignOut" v-if="this.$route.meta.noLogin !== true "><v-icon>mdi-logout</v-icon>ログアウト</v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-if="this.$route.meta.noLogin !== true"
        v-model="drawer"
        fixed
        app
    >
      <v-list-item>
        <v-list-item-title class="title">
          Menu
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list nav dense rounded>
        <v-list-item-group>
          <v-list-item
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="menuItem.url"
            >
            <v-list-item-title><v-icon>{{ menuItem.icon }}</v-icon> {{ menuItem.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </header>
</template>

<script>
import menuContents from '../../config/menu.config'
// import firebase, { firebase_db } from "../../plugins/firebase"
import firebase from "../../plugins/firebase"

export default {
  name: "Header",
  data () {
    return {
      drawer: false,
      menuItems: menuContents.menuItems,
    }
  },
  created() {
    firebase.onAuth();
  },
  methods: {
    userSignOut() {
      firebase.logout();
    },
  }
}
</script>

<style scoped>

</style>