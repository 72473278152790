<template>
  <div>
    <DefaultHeader></DefaultHeader>
    <v-main>
      <router-view></router-view>
    </v-main>
    <DefaultFooter></DefaultFooter>
  </div>
</template>

<script>

import DefaultHeader from "../organisms/DefaultHeader";
import DefaultFooter from "../organisms/DefaultFooter";

export default {
  name: "Default",
  data:() => ({
    user: [],
  }),
  components: {
    DefaultHeader,
    DefaultFooter,
  },
  mounted() {
    this.user = this.$store.getters.users;
  }
}
</script>

<style scoped>

</style>